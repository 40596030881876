import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 */
export default function Logo() {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="300" height="100" viewBox="0 0 150 40"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,34.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M106 280 c-39 -35 -69 -103 -64 -146 2 -23 8 -30 32 -32 15 -2 42 2
58 9 25 10 31 10 38 -2 7 -11 15 -12 41 -2 23 9 35 9 49 0 15 -9 27 -8 54 6
20 9 36 13 36 8 0 -16 40 -23 55 -11 10 9 19 9 28 2 6 -5 30 -9 52 -8 38 1 41
3 67 59 27 56 40 67 53 47 5 -8 11 -7 21 1 24 20 16 27 -32 27 -45 0 -45 0
-71 -54 -14 -30 -32 -54 -39 -54 -17 0 -17 4 0 38 7 15 17 37 20 50 7 21 4 22
-39 22 -40 0 -52 -5 -76 -31 -16 -17 -29 -37 -29 -45 0 -16 -50 -43 -67 -37
-23 8 -14 22 26 43 22 11 43 29 46 41 20 63 -70 37 -104 -30 -12 -22 -26 -43
-32 -46 -22 -14 -22 7 0 53 51 102 53 112 32 112 -14 0 -28 -19 -52 -70 -42
-91 -78 -122 -113 -97 -21 16 -7 69 34 126 34 47 66 56 56 16 -7 -29 9 -34 22
-7 27 51 -47 59 -102 12z m244 -59 c0 -13 -29 -41 -41 -41 -10 0 -5 13 14 38
11 14 27 16 27 3z m110 -8 c0 -21 -36 -83 -48 -83 -19 0 -20 14 -5 44 28 57
53 74 53 39z"/>
<path d="M753 278 c-52 -55 -80 -127 -62 -159 12 -23 66 -26 95 -5 17 13 21
12 37 -2 25 -22 65 -6 95 38 26 39 51 55 33 22 -26 -49 -4 -85 39 -62 16 9 25
9 35 0 10 -9 19 -9 35 0 13 7 26 8 33 2 7 -5 31 -9 54 -8 41 1 43 3 70 56 15
30 34 56 42 58 15 5 13 -7 -10 -65 -17 -41 -2 -62 33 -46 16 7 29 6 50 -6 29
-16 32 -31 8 -39 -9 -3 -11 1 -6 13 5 15 4 16 -9 5 -37 -31 1 -61 42 -34 20
12 103 166 103 189 0 3 -19 5 -43 5 -52 0 -81 -19 -107 -71 -20 -38 -40 -52
-40 -26 1 6 7 23 15 37 27 47 20 60 -33 60 l-48 0 -27 -55 c-15 -30 -34 -55
-42 -55 -20 0 -19 2 10 60 24 48 25 50 5 50 -14 0 -27 -15 -45 -49 -13 -27
-30 -53 -35 -56 -23 -14 -23 7 -1 53 13 26 30 62 37 80 13 30 13 32 -6 32 -14
0 -24 -10 -31 -30 -9 -25 -16 -30 -43 -30 -20 0 -40 -8 -51 -20 -21 -23 -35
-26 -35 -6 0 7 -6 19 -14 26 -23 19 -77 -13 -107 -64 -29 -47 -69 -65 -90 -39
-23 27 50 163 88 163 11 0 14 -7 9 -26 -5 -19 -3 -25 6 -22 7 3 14 17 16 31 3
25 1 27 -35 27 -31 0 -45 -7 -70 -32z m160 -80 c-9 -50 -53 -92 -53 -52 0 21
38 84 51 84 4 0 6 -15 2 -32z m137 22 c0 -23 -41 -90 -55 -90 -18 0 -19 14 -4
43 23 45 59 73 59 47z m370 1 c0 -21 -32 -82 -46 -88 -22 -8 -26 1 -14 33 17
45 60 84 60 55z"/>
<path d="M1156 285 c-6 -17 17 -31 34 -20 5 3 10 13 10 21 0 19 -36 18 -44 -1z"/>
</g>
</svg>
  );
}